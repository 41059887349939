import { API_EXODUS_URL } from 'constants/api';

import exodusInstance from 'lib/api/instances/exodus';
import { APIPromiseResponse, APIResponse } from '../../../@types/api-response';
import { NotificationSettingsList } from '../../../@types/notifications';

const requestExodusDefault = exodusInstance.defaultInstance;
const requestExodus = exodusInstance.noAlert;

const USER_SETTING_URL = `${API_EXODUS_URL}/user/setting`;
const INVITATION_SETTING_URL = `${API_EXODUS_URL}/chat/v2/invitations/setting`;
const NOTIFICATION_SETTING_URL = `${API_EXODUS_URL}/notification/settings`;
const NOTIF_V2_SETTING_URL = `${API_EXODUS_URL}/notification/v2/settings`;

interface updatePasswordParams {
  change_token: string;
  new: string;
  verify: string;
}

enum deviceType {
  DEVICE_TYPE_UNSPECIFIED = 'DEVICE_TYPE_UNSPECIFIED',
  DEVICE_TYPE_ANDROID = 'DEVICE_TYPE_ANDROID',
  DEVICE_TYPE_IOS = 'DEVICE_TYPE_IOS',
  DEVICE_TYPE_WEB = 'DEVICE_TYPE_WEB',
  DEVICE_TYPE_DESKTOP = 'DEVICE_TYPE_DESKTOP',
}

enum loginType {
  LOGIN_TYPE_UNSPECIFIED = 'LOGIN_TYPE_UNSPECIFIED',
  LOGIN_TYPE_BY_USERNAME = 'LOGIN_TYPE_BY_USERNAME',
  LOGIN_TYPE_BY_GOOGLE = 'LOGIN_TYPE_BY_GOOGLE',
  LOGIN_TYPE_BY_FACEBOOK = 'LOGIN_TYPE_BY_FACEBOOK',
  LOGIN_TYPE_BY_APPLE = 'LOGIN_TYPE_BY_APPLE',
  LOGIN_TYPE_BY_TOUCHID = 'LOGIN_TYPE_BY_TOUCHID',
  LOGIN_TYPE_BY_SSO = 'LOGIN_TYPE_BY_SSO',
  LOGIN_TYPE_BY_USER_KEY = 'LOGIN_TYPE_BY_USER_KEY',
}

interface deviceListItem {
  device: {
    name: string;
    type: deviceType;
  };
  geo_location: {
    city: string;
    latitude: number;
    longitude: number;
    region: string;
  };
  is_current_device: boolean;
  login: {
    created_at: string;
    type: loginType;
    updated_at: string;
  };
  status: string;
  uuid: string;
}

const getChangePasswordToken = (data): Promise<APIResponse> =>
  requestExodusDefault.post(`${USER_SETTING_URL}/token`, data);

const updatePassword = (data: updatePasswordParams): Promise<APIResponse> =>
  requestExodusDefault.put(`${USER_SETTING_URL}/password`, data);

const getNotificationSettings = (): Promise<
  APIResponse<NotificationSettingsList>
> => requestExodusDefault.get(`${NOTIF_V2_SETTING_URL}`);

interface updateNotifParams {
  type: string | number;
  settings: {
    key: string;
    value: boolean;
  }[];
}
const updateNotificationSetting = (
  data: updateNotifParams,
): Promise<APIResponse> =>
  requestExodus.put(`${NOTIFICATION_SETTING_URL}`, data);

const getInvitationSetting = (): Promise<APIResponse> =>
  requestExodus.get(`${INVITATION_SETTING_URL}`);

const updateInvitationSetting = (data): Promise<APIResponse> =>
  requestExodusDefault.put(`${INVITATION_SETTING_URL}`, data);

// Update email
const updateEmail = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/email`, data);

const confirmEmailOTP = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/email/confirm`, data);

// Update phone
const updatePhone = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/phone`, data);

const confirmPhoneOTP = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/phone/confirm`, data);

const getDeviceList = (): APIPromiseResponse<{
  total: number;
  device_sessions: deviceListItem[];
  next_cursor: string;
}> => requestExodus.get('/auth/session/devices');

const removeDevice = (props: {
  uuid: string;
  password: string;
}): APIPromiseResponse =>
  requestExodusDefault.post(`/auth/session/device/${props.uuid}/remove`, {
    password: props.password,
  });

const validateIdentity = (identity: string): Promise<APIResponse> =>
  requestExodusDefault.post('/auth/v2/pin/reset/validate', {
    identity,
  });

export { deviceType, loginType };

export default {
  getChangePasswordToken,
  updatePassword,
  getNotificationSettings,
  updateNotificationSetting,
  getInvitationSetting,
  updateInvitationSetting,
  updateEmail,
  confirmEmailOTP,
  updatePhone,
  confirmPhoneOTP,
  getDeviceList,
  removeDevice,
  validateIdentity,
};
